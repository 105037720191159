$( document ).ready(function() {
    const pBoxCollection = $(".fnsb-item-programs");
    const vBoxCollection = $(".fnsb-item-vehicles");
            // //grab the current offers disclaimers from 'offer_disclaimer'
            // if ($('.home-promotion-iframe-disclaimer .disclaimer-callout').length){
            //     $('.home-promotion-iframe-disclaimer .disclaimer-callout').data('disclaimers-content', offer_disclaimer);  
            // }
            
    // Refresh page if user hits the back button
    window.addEventListener( "pageshow", function ( event ) {
        var historyTraversal = event.persisted || ( typeof window.performance != "undefined" && window.performance.navigation.type === 2 );
        if ( historyTraversal ) {
            // Handle page restore.
            window.location.reload();
        }
    });


    //Added vehicles to dropdown
    // addVehiclesDropDown();

    /* Filter controls Sort By Link Button Click */
    $(".filter-nav-sortby-btn-programs").on("click", function () {
        $(".filter-nav-sortby-inner-programs").slideDown(300);
        $(".img-drop-programs").show(300);
    });
    /* Filter controls Sort By Option Click */
    $(".fnsb-item-programs").on("click", function () {
        $(".filter-nav-sortby-btn-programs").html($(this).html() + '<span class="acpv-icon chevron-down-red"></span>');
        $(".filter-nav-sortby-inner-programs").slideUp(300);
        $(".img-drop-programs").hide();
    });

    /* Filter controls Sort By Link Button Click */
    $(".filter-nav-sortby-btn-vehicles").on("click", function () {
        $(".filter-nav-sortby-inner-vehicles").slideDown(400);
        $(".img-drop-vehicles").show(300);
    });
    /* Filter controls Sort By Option Click */
    $(".fnsb-item-vehicles").on("click", function () {
        $(".filter-nav-sortby-btn-vehicles").html($(this).html() + '<span class="acpv-icon chevron-down-red"></span>');
        $(".filter-nav-sortby-inner-vehicles").slideUp(400);
        $(".img-drop-vehicles").hide();
    });


    //ada updates
    /* Programs Filter controls Sort By Link Button Click */
    $(".filter-nav-sortby-btn-programs").on("click", function () {
        $(".filter-nav-sortby-inner-programs").slideDown(300);
        $(".img-drop-programs").show(300);
        $(this).attr('aria-expanded',true);
        pBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(pListIndex).addClass(cssClass).attr('aria-selected',true).focus();
     });

    $(".filter-nav-sortby-btn-programs").on("keyup", function (e) {
        e.stopPropagation();
        if (e.key === "Enter") {
            $(".filter-nav-sortby-inner-programs").slideDown(300);
            $(".img-drop-programs").show(300);
            $(this).attr('aria-expanded',true);
            console.log('vbox',pBoxCollection)
            pBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(pListIndex).addClass(cssClass).attr('aria-selected',true).focus();
        }
     });


     $('.fnsb-item-programs').on('keyup', function(e){
        e.stopPropagation();
        e.preventDefault();
        if (e.key === 'ArrowDown') {  
            programsNavigate(1);
        }
        if(e.key === 'ArrowUp') {
            programsNavigate(-1);
        }
        if (e.key === 'Enter' && $(".filter-nav-sortby-btn-programs").attr('aria-expanded') == 'true') {
            $(this).trigger('click');
        }
     })

    /* Filter controls Sort By Option Click */
    $(".fnsb-item-programs").on("click", function () {
        $(".filter-nav-sortby-btn-programs").attr('aria-expanded',false);
        $(".filter-nav-sortby-btn-programs").focus();
        $(".filter-nav-sortby-btn-programs").html($(this).html() + '<img src="/images/map/icon-sortby-down.png" alt="">');
        $(".filter-nav-sortby-inner-programs").slideUp(300);
        $(".img-drop-programs").hide();
    });


    /* Vehicles Filter controls Sort By Link Button Click */
    $(".filter-nav-sortby-btn-vehicles").on("click", function () {
        $(".filter-nav-sortby-inner-vehicles").slideDown(400);
        $(".img-drop-vehicles").show(300);
        $(this).attr('aria-expanded',true);
        vBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(vListIndex).addClass(cssClass).attr('aria-selected',true).focus();
    });

    $(".filter-nav-sortby-btn-vehicles").on("keyup", function (e) {
        e.stopPropagation();
        e.preventDefault();
        if (e.key === "Enter") {
            $(".filter-nav-sortby-inner-vehicles").slideDown(400);
            $(".img-drop-vehicles").show(300);
            $(this).attr('aria-expanded',true);
            console.log('vbox',vBoxCollection)
            vBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(vListIndex).addClass(cssClass).attr('aria-selected',true).focus();
        }

    });          
    
    $('.fnsb-item-vehicles').on('keyup', function(e){
        e.stopPropagation();
        if (e.key === 'ArrowDown') {  
            vehiclesNavigate(1);
        }
        if(e.key === 'ArrowUp') {
            vehiclesNavigate(-1);
        }
        if (e.key === 'Enter' && $(".filter-nav-sortby-btn-vehicles").attr('aria-expanded') == 'true') {
            $(this).trigger('click');
        }
     })

    /* Filter controls Sort By Option Click */
    $(".fnsb-item-vehicles").on("click", function () {
        $(".filter-nav-sortby-btn-vehicles").attr('aria-expanded',false);
        $(".filter-nav-sortby-btn-vehicles").focus();
        $(".filter-nav-sortby-btn-vehicles").html($(this).html() + '<img src="/images/map/icon-sortby-down.png" alt="">');
        $(".filter-nav-sortby-inner-vehicles").slideUp(400);
        $(".img-drop-vehicles").hide();
    });


    // Detect if mobile device
    var detectTap;

    $('.vehicle').on('touchstart', function() {
        //alert('touchstart');
        detectTap = true; //detects all touch events
    });

    $('.vehicle').on('touchmove', function() {
        //alert('touchmove');
        detectTap = false; //Excludes the scroll events from touch events
    });

    $('.vehicle').on('click touchend', function(event) {
        //alert('click touchend');
        if (event.type == "click") detectTap = true; //detects click events 
           if (detectTap){
              // event.preventDefault();
            //   window.location.href = $('.vehicle a').attr('href');
        }
    });


    // Offers carousel used on the homepage offers
    var owl = $('.owl-carousel').owlCarousel({
        loop: true,
        items: 1, 
        dots: true, 
        autoplay: false, 
        nav: true, 
        navText: ["<img aria-hidden='true' role='button' src='/public/images/home/left-arrow-white.png' alt='previous'>", "<img aria-hidden='true' role='button' src='/public/images/home/right-arrow-white.png' alt='next'>"],
        afterAction : function() {
            $('#offercarousel .owl-item').attr('aria-selected','false');
            $('#offercarousel .owl-item.active').attr('aria-selected','true');
          }
   	});
    
    //hide cloned slides from keyboard and screen reader
    $('.owl-item.cloned').each(function(i, obj) {
        $(this).attr('aria-hidden', 'true');
        $(this).find('a').attr('tabindex', '-1');
        });

    //hide slider nav dots from screen reader
    $('.owl-dots').attr('aria-hidden', 'true');


    $('.owl-item a').on('keyup', function(e) {
        e.stopPropagation();
            if (e.key === "Enter") {
                $(this).find('img').trigger('click');
            }
    });


    //add keyboard accessibility for slides
    $('.owl-item a').on('keyup', function(e) {
        e.stopPropagation();
            if (e.key === "Enter") {
                $(this).find('img').trigger('click');
            }
    });


    //modal functionality
    const mp_modal = $('#modal-window');
    const mp_modalClose = $('#modal-window .close');
    $('#modal-window').attr('aria-hidden','false').attr('aria-label','Learn more about acrua maintenance program').attr('role','dialog');
    $('#modal-window .close').attr('tabindex',"0").attr('role','button').attr('aria-label','close modal')

    const showModal = () => {
        $('#modal-window').attr('aria-hidden','true');
        mp_modal.show();
        mp_modalClose.focus();
    }

    const hideModal = () => {
        $('#modal-window').attr('aria-hidden','false');
        mp_modal.hide();
        // owl.trigger('to.owl.carousel', [1,0]);
        $('#modal-button-desktop img').focus();
    }

    //modal open/close
    $(document.body).on("click", "#modal-button-desktop", function(e) {
        e.preventDefault();
        showModal();
    });

    $(document.body).on("click", "#modal-button-tablet", function(e) {
        e.preventDefault();
        showModal();
    });

    $(document.body).on("click", "#modal-button-mobile", function(e) {
        e.preventDefault();
        showModal();
    });

    $(document.body).on("click", "#modal-window .close", function(e) {
        hideModal();
    });

    $(document.body).on('keypress', "#modal-window .close", function(e) {
        if(e.which == 13) {
            $(this).trigger('click');
        }
    });

    //modal trapping

    const trapmodal = document.querySelector('#modal-window');

    window.onclick = function(event) {
        if (event.target == trapmodal) {
            hideModal();
        }
    };

    const focusableElements = '[href], [tabindex]:not([tabindex="-1"])';
    const firstFocusableElement = trapmodal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = trapmodal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    
    
    document.addEventListener('keydown', function(e) {
    let isTabPressed = e.key === 'Tab' || e.key === 'Shift';
    
    if (!isTabPressed) {
        return;
    }
    
    if (e.shiftKey) { // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus(); // add focus for the last focusable element
        e.preventDefault();
        }
    } else { // if tab key is pressed
        if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus(); // add focus for the first focusable element
        e.preventDefault();
        }
    }
    });
    
    firstFocusableElement.focus();


//end modal functions


    callFloodlight('home-page');
    
 
});

// function addVehiclesDropDown(){
//     let list = ['ILX', 'MDX', 'RDX', 'RLX ', 'TLX'];

//     list.forEach((item) => {
//        $(".filter-nav-sortby-inner-vehicles").append('<div class="fnsb-item-vehicles">' + item + '</div>');
//     });   
// }

$("button.search-button").on("click", searchInventoryButton);

function yearsString(startingYear) {
    let thisYear = new Date().getFullYear();
	let stringYears = `yearRange=${ startingYear },${ thisYear }`;
	
    return stringYears;
}

function searchInventoryButton(){
    let programText = $('.filter-nav-sortby-btn-programs').text();
    let vehicleText = $('.filter-nav-sortby-btn-vehicles').text();
    let precisionYears = yearsString(2016);
	let precisionUsedYears = yearsString(2011); 
	let qsParams = [];
    
	switch (programText.trim()) {
		case 'All programs':
			qsParams.push('program=T2,BT,U');
			qsParams.push(precisionYears);
			break;
		case 'Precision Certified':
			qsParams.push('program=T2');
			qsParams.push(precisionYears);
			break;
		case 'Precision Used':
			qsParams.push('program=BT');
			qsParams.push(precisionUsedYears);
			break;
		default:
			qsParams.push('program=U');
			break;
	}
	
	if (vehicleText.trim() === 'All vehicles') {
		qsParams.push('model=ILX,MDX,RDX,RLX,TLX');		
	}
	else {
		qsParams.push(`model=${vehicleText}`)
	}

    let url = window.acpv.createJumpLink(`/shopping/inventory/search?${qsParams.join('&')}`);

    window.location.href = url;
}

window.pListIndex = 0;
window.vListIndex = 0;
const cssClass = "optionfocus";

var programsNavigate = (diff) => {
    const pBoxCollection = $(".fnsb-item-programs");
    pListIndex += diff;
    if (pListIndex >= pBoxCollection.length) {
        pListIndex = 0;
    }
    if (pListIndex < 0) {
        pListIndex = pBoxCollection.length - 1;
    }
    pBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(pListIndex).addClass(cssClass).attr('aria-selected',true).focus();
}

var vehiclesNavigate = (diff) => {
    const vBoxCollection = $(".fnsb-item-vehicles");
    vListIndex += diff;
    if (vListIndex >= vBoxCollection.length) {
        vListIndex = 0;
    }
    if (vListIndex < 0) {
        vListIndex = vBoxCollection.length - 1;
    }
    vBoxCollection.removeClass(cssClass).attr('aria-selected',false).eq(vListIndex).addClass(cssClass).attr('aria-selected',true).focus();
}
